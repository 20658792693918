.App {
  text-align: center;

  height: auto;
}

body {
  background-color: #ecf0f9 !important;
  /* border: 2px solid red; */
  /* width: 100vw !important */

}

/* styles.css */
.background-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./Assets/std-logo.png');
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-color: rgba(10, 61, 61, 0.829);
  background-position: center center;
  filter: blur(5px);

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}