.dashboard-box {
    background: linear-gradient(#dae8fa, #b7d2f1);
    border-radius: 10px;
    color: rgb(0, 0, 0);
    width: 300px;


}

.dashboard-box-1 {
    background: linear-gradient(#9ab3d4, #b2e6e7);
    border-radius: 10px;
    color: rgb(0, 0, 0);
    width: 300px;


}

.dash-link:active {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.95);
}

.dash-link {
    cursor: pointer;
}

.dash-link:hover {
    outline: 1px solid #1fb5ac;
}


.min-box {
    width: 45px;
    height: 45px;
    font-size: 30px;
    border-radius: 50%;
    background: #dd4306;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.min-box1 {
    width: 45px;
    height: 45px;
    font-size: 30px;
    border-radius: 50%;
    background: #037c74;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.min-box2 {
    width: 45px;
    height: 45px;
    font-size: 30px;
    border-radius: 50%;
    background: #6503a7b7;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-span {
    font-size: 18px;
    font-weight: 600;
}

.Order-span {
    font-size: 15px;
    padding-top: 2px
}

@media(max-width: 767px) {
    .dashboard-box {
        background: linear-gradient(#dae8fa, #b7d2f1);
        ;
        border-radius: 10px;
        color: rgb(0, 0, 0);
        width: 98%;
    }

    .dashboard-box-1 {
        background: linear-gradient(#9ab3d4, #b2e6e7);
        border-radius: 10px;
        color: rgb(0, 0, 0);
        width: 98%;
        ;
    }
}