.user-h4 {
  font-size: 18px;
  color: #ffffff;
  cursor: default;
}

.container {
  margin-top: 20px;
}

.rdt_TableCell div:first-child {
  text-wrap: wrap !important;
  text-align: start;
  /* border: 1px solid #000000; */
  /* width: 100px; */
}

.___SOutline_letz7_gg_ {
  background-color: #e5ebf9 !important;
}

.___STimePickerSeparator_1a08p_gg_ {
  font-weight: 600 !important;
}



.user-box-1 {
  background-color: #08272a;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-wrap: wrap;
}

.btn-dangerpdf {
  background-color: #fa8564;
  border-color: #fa8564;
  color: #FFFFFF;
  width: 180px;

}

.btn-dangerpdf:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  width: 180px;

}

.btn-dangerexcel {
  background: linear-gradient(#b1e4e6, #99b1d3);
  ;
  /* border-color: #57c8f1; */
  color: #000000;
  font-size: 17px;
  font-weight: 500;
  width: 180px;
}

.btn-dangerexcel:hover {
  color: rgb(0, 0, 0);
  background-color: #5bc0de;
  border-color: #46b8da;
  width: 180px;
}

.btn-primaryadd {
  background-color: #0f8c8e;
  border-color: #1fb5ad;
  color: #FFFFFF;
}

.btn-primaryadd:hover {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}

.select-width {
  width: 100%;
  /* height: 50px; */
  background-color: #e5ebf9;
}

.select-width-demo {
  width: 550px;
  background-color: #e5ebf9;
}

.table-striped>tbody>tr:nth-child(odd) {
  background-color: #F7E3C0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 44px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F0AD4E;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 9px;
  background-color: white;
  -webkit-transition: .7s;
  transition: .7s;
}

input:checked+.slider {
  background-color: #2ab934;
}

.text-muted222 {
  color: red !important;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(75px);
  -ms-transform: translateX(75px);
  transform: translateX(75px);
}

.btn-infos {
  background-color: #144348;
}

.btn-infos:hover {
  background-color: #144348;
}

.btn-infoss {
  background-color: #093c60;
}

.btn-infoss:hover {
  background-color: #093c60;
}

.btn-warnings:hover {
  background-color: #470e27;
}

.btn-warnings {
  background-color: #470e27;
}

.btn-dangers {
  background-color: #5e6604;
}

.btn-dangers:hover {
  background-color: #5e6604;
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -60%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
  display: block;
}

input:checked+.slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn-save {
  background-color: #0f8c8e;
  border-color: #0f8c8e;
  color: #FFFFFF;
  width: 100px;
}

.btn-save:hover {
  background-color: #0f8c8e;
  border-color: #0f8c8e;
  color: #FFFFFF;
  width: 100px;
}

.btn-Cancel {
  background-color: #093c60;
  border-color: #093c60;
  color: #fff;
  width: 120px;
}

.btn-Cancel:hover {
  background-color: #093c60;
  border-color: #093c60;
  color: #fff;
  width: 120px;
}

.btn-active {
  background-color: #06737a;
  color: white;
  font-size: 12px;
}

.btn-Inactive {
  background-color: #093c60;
  color: white;
  font-size: 12px;

}

.btn-active:hover {
  background-color: #06737a;
  color: white;
  font-size: 12px;
}

.btn-Inactive:hover {
  background-color: #093c60;
  color: white;
  font-size: 12px;

}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xxs {
  padding: 1px 6px;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-Edit {
  background-color: #fa8564;
  border-color: #fa8564;
  color: #FFFFFF;
  width: 100px;
}

.btn-Edit:hover {
  background-color: #fa8564;
  border-color: #fa8564;
  color: #FFFFFF;
  width: 100px;
}

tbody tr td {
  font-size: 12px !important;
  padding-top: 15px !important;

}

thead tr th {
  font-size: 11px !important;
}

tbody tr th {
  font-size: 11px !important;
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * 1);
  background: rgb(255, 255, 255);
  border: 1px solid transparent;
  color: rgb(0, 0, 0);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.nav-tabs .nav-link.active {
  color: white;
  background-color: #093c60;
  border-color: #093c60;
}

@media(max-width: 576px) {
  .select-width {
    width: 98%;
  }

  .table-responsive {
    display: block !important;
    overflow-x: scroll !important;
    width: 320px !important;

  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(21, 45, 152, 0.893) !important;
  color: white !important;
}


.checkboxoff {
  display: none;
}

.switchoff {
  align-items: center;
  background-color: gray;
  border-radius: 500px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  user-select: none;
  width: 80px;
}

.checkboxoff:checked~.switchoff {
  background-color: #428BCA;
}

.checkboxoff:not(:checked)~.switchoff {
  background-color: gray;
}

.switch__left,
.switch__right {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.checkboxoff:checked~.switchoff .switch__left {
  visibility: hidden;
}

.checkboxoff:not(:checked)~.switchoff .switch__right {
  visibility: hidden;
}

.switch__circleoff {
  height: 40px;
  padding: 5px;
  position: absolute;
  transition: all 0.1s linear;
  width: 40px;
}

.checkboxoff:checked~.switchoff .switch__circleoff {
  left: 0;
  right: calc(100% - 40px);
}

.checkboxoff:not(:checked)~.switchoff .switch__circleoff {
  left: calc(100% - 40px);
  right: 0;
}

.switch__circle-inneroff {
  background-color: white;
  border-radius: 50%;
  display: block;
  height: 100%;
  width: 100%;
}

.dLFHGV {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  min-height: 48px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(210 233 231) !important;
}

.djcrJL {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  min-height: 48px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(210 233 231) !important
}

.djcrJL:hover {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  min-height: 48px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(210 233 231) !important
}

.dLFHGV:hover {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  min-height: 48px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(210 233 231) !important;
}

.kJqETh {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: rgb(8, 39, 42);
  background-color: rgb(192, 223, 223) !important;
  min-height: 48px;
}

.kJqETh:hover {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: rgb(8, 39, 42);
  background-color: rgb(192, 223, 223) !important;
  min-height: 48px;
}

.box-col {
  display: flex;
  justify-content: space-evenly;
  align-items: end;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #c7d7df;
  border: 1px solid #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.box-col h6 {
  text-align: left;
  min-width: 100px;
}


.h4 {
  font-style: italic;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {

  background-color: #e5ebf9 !important;
}

.time-text {
  width: 100px !important;
}

.inputWrapper {
  position: relative;
  align-items: center;
  height: 100%;
  max-width: auto;
  display: flex;
}

.react-time-input-picker.is-disabled,
.react-time-input-picker.is-disabled * {
  cursor: not-allowed !important;
}

.inputWrapper:nth-child(2) {
  max-width: auto;
}

.inputWrapper:nth-child(1)::after {
  content: ":";
  display: flex;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  top: -1px;
}

.react-time-input-picker input:focus {
  caret-color: transparent;
  background-color: #dadada;
}


.inputWrapper__dropdown {
  position: absolute;
  left: 0;
  bottom: -205px;
  border-radius: 5px;
  height: 200px;
  overflow: auto;
  min-width: 150%;
  width: 150%;
  border: 1px solid #eeedee;
  box-shadow: 0 0 6px 0.2px #a0a0a0;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px 0;
  z-index: 30000000;
}

.manuallyDisplayDropdown .inputWrapper__dropdown {
  min-width: 110%;
  width: 110%;
}

.inputWrapper__dropdown.amPm {
  width: 110%;
  min-width: 110%;
  bottom: -81px;
  overflow-y: hidden;
  height: 76px;
}

.inputWrapper__dropdown span {
  min-height: 30px;
  color: #434343;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
  font-weight: 500;
  margin-bottom: 0;
  margin-bottom: 2px;
}

.inputWrapper__dropdown span.is-active,
.inputWrapper__dropdown span:hover {
  background: #dadada;
}

.inputWrapper__dropdown::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.inputWrapper__dropdown::-webkit-scrollbar-track {
  background-color: #eeedee;
  border-radius: 4px;
}

.inputWrapper__dropdown::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 4px;
}

.inputWrapper__dropdown::-webkit-scrollbar-thumb:hover {
  background: #434343;
}

.fullTime__wrapper {
  position: absolute;
  width: 110%;
  left: -5%;
  right: 5%;
  bottom: 0px;
}

.fullTime__wrapper .inputWrapper__dropdown.fullTime {
  left: 0;
  right: 0;
  width: 100%;
  min-width: 100%;
}

.fullTime__wrapper .inputWrapper__dropdown.fullTime span {
  font-weight: 500;
  font-size: 16px;
}

.fullTime__wrapper .inputWrapper__dropdown.fullTime div {
  height: 100%;
  display: inline-flex;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  margin-left: 10px;
  align-items: center;
}

.hidden-spinner {
  display: none;
}

.display-spinner {
  display: block
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  background: #2726264d;
  z-index: 1;
  border-radius: 5px;
}

.loader-containerssss {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(97, 96, 96, 0.801);
  z-index: 1;
  border-radius: 5px;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 8px solid !important;
  border-color: #012aaf #415dffd3 !important;
  border-radius: 50%;
  animation: spin-anim 2.0s linear infinite;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #e5ebf9;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #e5ebf9;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.responsive-table {
  overflow-x: auto !important;
  /* width: 100%; */
}

@media (max-width: 768px) {
  .responsive-table {
    font-size: 12px;
    width: 300px;
    /* overflow-x: auto !important; */
  }
}

.bWdFbf {
  position: relative;
  width: 100%;
  display: block !important;
}

.iMegPY {
  position: relative;
  width: 100%;
  display: block !important;
  /* display: table; */
}


.jCCftv {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  padding: 4px 16px 4px 24px;
  width: 100%;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-wrap: wrap;
  /* background-color: rgb(192, 223, 223); */
  min-height: 0px !important;
}


.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.7rem !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, 9px) scale(1);
  -moz-transform: translate(14px, 9px) scale(1);
  -ms-transform: translate(14px, 9px) scale(1);
  transform: translate(14px, 9px) scale(1);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 4.5px 14px !important;
}


.css-182didf {
  appearance: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 4.5px 14px !important;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: rgba(21, 45, 152, 0.893) !important;
  color: white !important;
} */
.css-1km1ehz.Mui-selected {
  background: rgba(21, 45, 152, 0.893) !important;
  color: white !important;
}

.css-cktaik {
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.7rem !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(14px, 9px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.lable-text {
  font-weight: 700;
}


.ant-picker {
  background-color: #e5ebf9 !important;
  width: 110px;
  border-color: #949393c2 !important;
  cursor: pointer;
}

.ant-picker:hover {
  border-color: #000000c2 !important;
}

.ant-picker-input input {
  font-weight: 600;
  text-align: center;
  font-size: 18px !important;
  cursor: pointer;
}

.ant-picker-input ::placeholder {
  display: flex;
  align-items: center;
  color: #7c7c7c !important;
  font-weight: normal;
  font-size: 14px !important;
}

/* Add custom scrollbar styles */
.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 5px !important;
  /* Width of the scrollbar */
}

.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  /* Color of the scrollbar thumb */
  border-radius: 10px !important;

  /* Rounded corners for the thumb */
}