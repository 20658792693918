.wrapper,
.sct {
    height: 100vh !important;
    margin: 0;
}

input:active,
input:focus,
input:visited,
input:hover {
    outline: none;
}

.sct input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
    display: block;
    width: 100%;
    /* padding: 1rem 1rem; */
    background: #e5ebf9;
    font-family: sans-serif;
    font-weight: 600;
    color: rgb(78, 78, 78);
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 1rem;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.sct input[type="submit"] {
    display: block;
    width: 100%;
    margin: 1.5rem 0;
    padding: 1.2rem;
    background: #003333;
    border: 1px solid rgb(212, 212, 212);
    font-family: sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(247, 247, 247);
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 1rem;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.sct input[type="submit"]:active,
input[type="submit"]:focus,
input[type="submit"]:visited,
input[type="submit"]:hover {
    background: rgb(34, 156, 255);
}

button,
input[type="submit"] {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

form>h3 {
    text-align: center;
}

.forgot-remember {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "centerLeft centerRight";
}

.sct {
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand {
    grid-area: centerLeft;
    background-color: #003333;
    animation: colorAnimate 30s;
    -webkit-animation: colorAnimate 30s;
    animation-iteration-count: infinite;
    -webkit-iteration-count: infinite;
}

.brand>h3 {
    padding: .75rem;
    font-weight: 800;
    outline: 3px solid #fff;
    color: #fff;
}

.login {
    grid-area: centerRight;
}

.login>form {
    width: 400px;
    margin: 0 3rem;
}

/*Checkbox*/

.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border: 0px solid #000000;
}

.control:hover input~.control_indicator,
.control input:focus~.control_indicator {
    background: #cccccc;
}

.control input:checked~.control_indicator {
    background: #2aa1c0;
}

.control:hover input:not([disabled]):checked~.control_indicator,
.control input:checked:focus~.control_indicator {
    background: #0e6647;
}

.control input:disabled~.control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}

.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}

.control input:checked~.control_indicator:after {
    display: block;
}

.control-checkbox .control_indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.control-checkbox input:disabled~.control_indicator:after {
    border-color: #7b7b7b;
}

/*Social Sign Up*/

.social-sign {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
}

.social-sign a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 2rem;
}

.social-sign>a:nth-child(2) {
    margin: 0 .5rem;
}

.fa-hand-o-down {
    margin-top: .5rem;
}

.logo-width {
    width: 500px;
}

@media(max-width: 1024px) {
    .logo-width {
        width: 290px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(20%, 80vmax);
        grid-template-areas:
            "centerLeft"
            "centerRight";
    }

    .login>form {
        width: 100%;
        margin: 0 3rem;
    }

    .logo-width {
        width: 290px;
    }
}