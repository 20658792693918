.dx-scheduler-appointment {
  border-bottom: 1px solid transparent;
  position: absolute;
  cursor: default;
  -webkit-touch-callout: none;
  background-color: #337ab7;
  color: #fff;
  min-width: 20px !important;
  min-height: 4px;
  width: 10vw !important;
}

.schedule-table {
  min-width: 0px !important;
}

.dKzMcW {
  padding-left: 10px !important;
}

@-moz-document url-prefix() {
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
    position: relative;
  }

  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .disable-date {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

#outlined-basic {
  height: 15px !important;
}

.filter {
  display: flex !important;
  flex-direction: column;
  row-gap: 4px !important;
}

.filter .___SBoxInline_ibl27_gg_ {
  width: 40px !important;

}

/* background: linear-gradient(0.5turn, #091f38, #515e9f, #091f38); */
/* LMEX25 */
@media screen and (min-width: 1024px) and (max-width: 1560px) {
  .filter .___SBoxInline_ibl27_gg_ {
    width: 30px !important
  }
}

.filter .___STimePicker_1a08p_gg_ {
  height: 30px !important;
}

.Time-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time-day {
  display: flex;
  font-weight: bold;
  font-size: 12px;
}

.testing {
  display: flex;
  justify-content: space-around;
}

.timepicker {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .timepicker {
    flex-wrap: wrap;
  }
}

.week-time {
  font-size: 12px;
  font-weight: 600;
}

.search-btn {
  background-color: #144348 !important;
}

.delete-btn {
  background-color: rgb(173, 30, 30) !important;
  padding: 4px;
  border-radius: 5px;
}

.disable-date,
.dinner {
  height: 100%;
  width: 100%;
  padding: 0 !important;
}


.ghLWkf div:first-child {
  text-overflow: clip !important;
  white-space: normal !important;
  overflow: visible !important;
}

.hqQSuL {
  min-width: 0px !important;
}

.disable-date {
  background-color: #555555;
  padding: 0 !important;
  color: #555555;
}

.dx-scheduler-agenda .dx-scheduler-appointment {
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100% !important;
}

.dx-scheduler-header-panel-cell .disable-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dinner {
  background: rgba(53, 53, 53, 0.2);
  padding: 0px !important;
}

.dx-scheduler-time-panel-cell .dinner {
  color: #555555;
  font-weight: 400;
  background: transparent;
  padding: 0 !important;
}

.dx-draggable {
  cursor: auto;
  padding: 0 !important;
}

td.dx-scheduler-time-panel-cell .dinner .cafe {
  height: 200%;
  width: 100%;
  left: 50%;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position-y: 50%;
  -webkit-mask-position-x: 100%;
  margin-top: -4px;
  background-color: #555555;
}

.dx-scheduler-date-table-cell {
  padding: 0px !important;
  opacity: 1;

}

@media all and (-ms-high-contrast: none) {
  td.dx-scheduler-time-panel-cell .dinner .cafe {
    background-color: transparent;
  }
}

.box-gray {
  width: 50px;
  height: 30px;
  border-radius: 5px;
  background-color: rgba(53, 53, 53, 0.2);
}

.box-white {
  width: 50px;
  height: 30px;
  border-radius: 5px;
  background-color: white;
}

.box-blue {
  width: 50px;
  height: 30px;
  border-radius: 5px;
  background-color: #337ab7;
}


.option {
  display: flex;
}

.option>span {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.time-selector {
  padding: 4px;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(230, 226, 204);
  text-align: center;
}

.custom-select {
  appearance: none;
  /* Hide the default dropdown arrow */
  font-family: sans-serif;
  color: rgb(78, 78, 78);
  font-weight: 600;
  padding: 4px;
  border-radius: 1rem;
  font-size: 16px;
  outline: none;
  width: 40%;
  border: 1px solid #e5ebf9;
  background-color: #e5ebf9;

  /* Adjust the width as needed */
  margin: 0 5px;
  cursor: pointer;
  transition: all .3s;
  position: relative;
  text-align: center;
}

option {
  padding: 0px 2px 1px;
  width: 100px;
  font-size: 14px;
  background-color: white;
}

/* .react-time-input-picker .arrow-down {
  display: none;
} */


.custom-select option:visited {
  background-color: #a3a3a3;
}

/* Style the scrollbar */
.custom-select::-webkit-scrollbar {
  width: 6px;

  /* Adjust the scrollbar width */
}

.custom-select::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 5px;
  height: 5px;
  height: 5px;
}

.custom-select::-webkit-scrollbar-track {
  background-color: lightgray;
}

/* Styling the scrollbar thumb (the draggable part) */


.custom-select::-webkit-scrollbar-thumb:hover {
  background-color: #9c9a9a;
  width: 10px;
  height: 5px;
}

.picker {
  display: flex;
  justify-content: space-evenly;

}

.ant-picker {
  background-color: #e5ebf9 !important;
  width: 110px;
  border-color: #949393c2 !important;

}

.ant-picker-focused {
  box-shadow: unset;
  border-color: none;
}

.ant-picker-input input {
  font-weight: 600;
  text-align: center;
  font-size: 18px !important;
}

.ant-picker-input ::placeholder {
  color: #7c7c7c !important;
  font-weight: normal;
  font-size: 14px !important;
}

/* Add custom scrollbar styles */
.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 5px !important;
  /* Width of the scrollbar */
}

.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  /* Color of the scrollbar thumb */
  border-radius: 10px !important;

  /* Rounded corners for the thumb */
}